/* src/components/Strains/Strains.css */
.strains-page {
    padding: 2vh 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-sizing: border-box;
    background-image: url('../../assets/strains/strains_backgrond.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.strains-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 100%);
    z-index: 1;
}

.temporary-message {
    position: relative;
    z-index: 2; /* Ensure this is above the background overlay */
    text-align: center;
    padding: 5vh 10vw;
    background-color: rgba(255, 255, 255, 0.95); /* Slightly transparent for layering effect */
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    backdrop-filter: blur(5px); /* Optional: adds a blur effect to the background */
    user-select: text; /* Ensure text can be selected */
}

.temporary-message h2 {
    font-size: 2.5vh;
    margin-bottom: 2vh;
    color: #333;
}

.temporary-message p {
    margin-bottom: 1.5vh;
    font-size: 2vh;
    color: #555;
}

.temporary-message a {
    color: #1170d7;
    text-decoration: none;
    font-weight: bold;
}

.temporary-message a:hover {
    text-decoration: underline;
}