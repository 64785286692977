/* src/components/Card/Card.css */
.card {
    display: flex;
    /* border: 1px solid #ec0a0a; */
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 2vh;
    width: 100%;
    align-items:  flex-start; /* Align items to the top */
    background-color: #ffffff;
  }
  
  .card-image {
    flex: 0 0 auto;
    width: 25vh;
    height: 25vh;
    margin: 1vh; /* Space between carousel and content */
    padding: 1vh; /* Add padding to content area */
    /* border: 1px solid #ec0a0a; */
  }
  
  .card-content {
    flex: 1; /* Take up the remaining space */
    /* padding: 1vh; */
    /* margin: 1vh; */
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ec0a0a; */
    width: 25vh;
    height: 25vh;
    }

  .card-title {
    width: 100%; /* Ensure the title takes the full width */
    /* border: 1px solid #ec0a0a; */
    font-size: 3vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    }
  
.card-info {
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ec0a0a; */
  font-size: 2vh; /* Consistent font size for titles */
}

.card-info-row {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 1vh; 
}

.card-info-row p {
  margin: 0 0 1vh;
  flex: 1; 
  padding: 0;
}

.species-name {
    font-style: italic;
  }
  
  .relative-text {
    font-size: 1.5vh; /* Adjust this value as needed */
    color: #666; /* Optional: change color to differentiate */
  }

  .observation-list {
    /* list-style-type: disc; */
    margin-left: 2vh;
    padding: 0 0 1vh;
    /* margin-bottom: 2vh; */
    /* padding: 0; */
    column-count: 3;
    font-size: 1.7vh; /* Consistent font size for title */
    column-gap: 3vh; /* Gap between columns */
  }
  
  .observation-list li {
    break-inside: avoid; /* Prevents breaking of list items across columns */
  }