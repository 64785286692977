/* src/components/Footer/Footer.css */

  .footer {
    width: 100%;
    padding: 3vh 0;
    text-align: center;
    background-color: #274744;
    color: #b1cdca;
    /* display: flex; */
    flex-wrap: wrap;
}

.footer p {
  padding: 0 10vw;
}