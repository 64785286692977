/* src/Navbar.css */

nav {
  display: flex; /* Aligns items in a row */
  justify-content: space-between; /* Positions logo and links on opposite ends */
  align-items: center; /* Centers items vertically */
  padding: 0 2vh; /* Adds padding on the left and right */
  background-color: #eef0ec; /* Background color */
  height: 13vh; /* Adjust height for thicker navbar */
}

.logo img {
  height: 10vh;
  max-height: 100%; /* Ensures logo does not exceed navbar height */
  width: auto; /* Maintains aspect ratio */
  padding: 0 3vh; /* Adjusted padding to ensure logo doesn't touch the edges */
  filter: brightness(0) saturate(100%) invert(27%) sepia(60%) saturate(1500%) hue-rotate(130deg) brightness(50%) contrast(120%);
}


.nav-links {
  list-style-type: none; /* Removes bullet points from list */
  margin: 0;
  padding: 0;
  display: flex; /* Displays links in a row */
  align-items: center;
}

.nav-links li {
  padding: 0 3vh /* Space between links */
}

.nav-links li a {
  color: #1e4744;
  font-size: 2.2vh;
  font-weight: bold;
  text-decoration: none;
}

.nav-links li a:hover {
  color: #b1cdca;
}

@media (max-width: 768px) {
  .logo img {
    height: 8vh; /* Smaller height for smaller screens */
    padding: 0 1vh;
  }

  nav {
    height: auto; /* Adjust height for responsive design */
  }

  .nav-links li {
    padding: 0 1.5vh; /* Reduce space between links */
    text-align: center; /* Ensure text is centered */
  }

  .nav-links li a {
    font-size: 1.8vh; /* Smaller font size for small screens */
    white-space: normal; /* Allow text wrapping on smaller screens */
    text-align: center; /* Center text when it wraps */
  }
}

